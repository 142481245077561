@import 'var'

html
	overflow-y: scroll
	background-color: #fff

	margin: 0
	font-family: 'HeroNew'
	font-weight: normal
	min-height: 100vh

	body
		color: #323E48
		margin: 0!important
		height: 100vh

.page-index
	background-color: #000
	color: #fff

sup
	font-size: 0.65em
	top: 0.15em
	position: relative

.page-wstart
	background: #fff
	height: 100%
	position: absolute
	width: 100%

	#pjAcceptCookieBar
		display: none
.ball-icon
	font-size: 83%
	height: 18px
	margin-bottom: -3px
.ball img
	position: relative
	display: block
	margin: 0
	height: 1em
.back
	color: #AD7D66!important

.linktext
	white-space: nowrap
	display: inline-block
	overflow: hidden
	text-overflow: ellipsis
	max-width: 300px
.ball
	white-space: nowrap
	display: flex
	flex-direction: row
	flex-wrap: nowrap
	align-items: center
	//justify-content: center
	align-content: center
	justify-items: center
//input:-webkit-autofill
//  -webkit-box-shadow: inset 0 0 0 50px #fff !important
//  -webkit-text-fill-color: #000 !important

/*IE9*/
//*::selection
//  background-color: transparent
//
//*::-moz-selection
//  background-color: transparent

//*
//	-webkit-user-select: none
	/*IE10*/
// -ms-user-select: none/
// user-select: none
// -webkit-tap-highlight-color: rgba(0, 0, 0, 0)

//
.grecaptcha-badge
	display: none!important
	z-index: 100000
