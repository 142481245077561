=font-face($family: Arial, $type, $weight: normal, $style: normal, $display:swap)
	@font-face
		font-family: $family
		src: url('../fonts/#{$family}/#{$family}-#{$type}.eot')
		src: url('../fonts/#{$family}/#{$family}-#{$type}.eot?#iefix') format('embedded-opentype'), url('../fonts/#{$family}/#{$family}-#{$type}.woff') format('woff'), url('../fonts/#{$family}/#{$family}-#{$type}.ttf') format('truetype')
		font-weight: $weight
		font-style: $style
		font-display: $display

// +font-face('HeroNew', 'Regular', 400)
// +font-face('HeroNew', 'ExtraBold', 900)
// +font-face('HeroNew', 'Bold', 600)
// +font-face('HeroNew', 'Medium', 500)

+font-face('HeroNew', 'Regular', 400)
+font-face('HeroNew', 'Medium', 500)
+font-face('HeroNew', 'SemiBold', 600)
+font-face('HeroNew', 'Bold', 700)
+font-face('HeroNew', 'Super', 900)
